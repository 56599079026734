<template>
    <div id="rollup-container">
        <div class="container documents-container documents-container--pie-charts">
            <div class="columns mb-0" v-if="!documentsInfoLoading">
                <h2 class="general-container__title mb-0">
                    <Tooltip :tooltipText="'The graphs below show current FY documents along with LBRs for the next fiscal year.'">
                        <span :class="'has-tooltip'">
                            <p class="expand-label expand-label--on is-active" @click="clickCollapse();">- <span>Current Document Stats</span></p>
                            <p class="expand-label expand-label--off" @click="clickCollapse();">+ <span>Current Document Stats</span></p>
                        </span>
                    </Tooltip>
                </h2>
            </div>
            <div id="documents-pie-chart-container" class="is-active">
                <div class="columns" v-if="!documentsInfoLoading">
                    <div class="column is-full-mobile">
                        <documents-pie-chart :uniqueId="uniqueId" :documents="documentsInfo.domainCounts" title="By Domain" type="domain"/>
                    </div>
                    <div class="column is-full-mobile">
                        <documents-pie-chart :uniqueId="uniqueId" :documents="documentsInfo.agencyCounts" title="By Agency" type="agencyId"/>
                    </div>
                </div>
                <div class="documents-loading" v-if="documentsInfoLoading">
                    <Preloader />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Preloader from "./preloader";
    import DocumentsPieChart from "./documents-pie-chart";
    import Tooltip from "./tooltip";
    import {computed} from "vue";
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'DocumentsPieChartContainer',
        props: ['documentsInfoLoading', 'documentsInfo'],
        components: {
            Preloader,
            DocumentsPieChart,
            Tooltip,
        },
         methods: {
            clickCollapse() {
                let element = document.getElementById('documents-pie-chart-container');
                let toggleA = document.getElementsByClassName('expand-label')[0];
                let toggleB = document.getElementsByClassName('expand-label')[1];
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    toggleA.classList.remove('is-active');
                    toggleB.classList.add('is-active');
                } else {
                    element.classList.add('is-active');
                    toggleA.classList.add('is-active');
                    toggleB.classList.remove('is-active');
                }
            },
        },
        setup(props) {
            const uniqueId = computed(() => {
                return uuidv4();
            });

            return {
                uniqueId,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .general-container__title {
        text-align: center;
        width: 100%;
    }

    #documents-pie-chart-container {
        display: none;
        transition: all 1s ease-in-out;
        &.is-active {
            display: block;
            padding-top: 10px;
        }
    }

    @include block('expand-label') {
        display: none;
        font-weight: bold;
        padding-bottom: 0px;
        color: $blue--dark;
        padding: 5px 10px;
        margin-bottom: 0px;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        &.is-active {
            display: inline-block;
        }
    }

    #rollup-container {
        margin-top: 70px;
    }

    .documents-loading {
        display: inline-block;
        text-align: center;
        width: 100%;
    }

    .mb-0 {
        margin-bottom: 0px;
    }
</style>
