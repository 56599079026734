<template>
    <!-- Page: pages/browse-documents -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Browse Budget Documents</h1>
        <userTools/>
    </div>

    <hr/>

    <documents-pie-chart-container
        :documents-info="documentsInfo"
        :documents-info-loading="documentsInfoLoading"
    />

    <div class="container documents-container">
        <div>
            <div class="column" v-if="!documentsInfoLoading">
                <documents-table
                    :not-on-agency-page="true"
                />
            </div>
        </div>
    </div>
    <!-- End Page: pages/browse-documents -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import Tooltip from "./tooltip";
    import DocumentsTable from "./documents-table";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBinoculars, faFolderOpen, faFolder } from '@fortawesome/free-solid-svg-icons';
    import { faFolderArrowDown } from '@fortawesome/pro-solid-svg-icons';
    import DocumentsPieChartContainer from "./documents-pie-chart-container";

    export default {
        components: {
            Preloader,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            Tooltip,
            DocumentsTable,
            FontAwesomeIcon,
            DocumentsPieChartContainer,
        },
        data() {
            return {
                faBinoculars: faBinoculars,
                faFolderOpen: faFolderOpen,
                faFolder: faFolder,
                faFolderArrowDown: faFolderArrowDown
            };
        },
        methods: {
        },
        computed: {
        },
        mounted() {
        },
        setup(props) {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const definitions = computed(() => store.getters.definitions);

            const documentsInfo = ref([]);
            const documentsInfoLoading = ref(true);

            RequestHandler.loadDocumentsInfo().then(data => {
                documentsInfo.value = data;
                documentsInfoLoading.value = false;
            });

            return {
                path: [{title: 'Browse Documents'}],
                showSearch,
                definitions,
                documentsInfo,
                documentsInfoLoading,
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .documents-container .column {
        margin-top: 50px;
        &.is-half-mobile {
            margin-top: 0px;
        }
    }
    .tac {
        text-align: center;
        width: 100%;
    }

    .documents-loading {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
</style>
