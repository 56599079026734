<template>
    <!-- Start Component: organisms/documents-pie-chart -->
    <div class="pie-chart-wrapper">
        <h3 class="pie-chart-title has-text-centered">{{ title }}</h3>
        <p class="details-name" :id="uniqueName"></p>
        <p class="details-amount" :id="uniqueAmount"></p>
        <div ref="chartEl" class="pie-chart-container"></div>
        <div class="chart-legend">
            <a v-for="(document, i) in documentsList"
            @click="toggleDocument(i)"
            class="legend-trigger"
            :data-name='clean(document.name)'
            :class="{'chart-highlighted': document.chartHighlighted}"
            >
                <span :style="{'background-color': document.color}" class="swatch"></span>
                <span class="legend-trigger__title">{{ titleize(document.name) + ' ' }}</span>
            </a>
        </div>
    </div>
    <!-- End Component: organisms/documents-pie-chart -->
</template>

<script>
    import { onMounted, ref, computed } from "vue";
    import { renderDocumentsChart } from "@/helpers/charts";
    import { idFormat } from "@/helpers/filter";
    import { CHART_COLORS } from "@/constants";
    import { useRouter, useRoute } from 'vue-router';
    import { v4 as uuidv4 } from "uuid";
    import { getElementByPartialId } from "@/helpers/utils";

    export default {
        name: 'DocumentsPieChart',
        props: ['documents', 'grouped', 'title', 'type', 'uniqueId'],
        components: {},
        setup(props) {
            const chartEl = ref(null);
            const documentsList = ref(props.documents || []);
            const router = useRouter();

            // Generate a unique ID for each instance
            const uniqueName = computed(() => {
                return 'details-name-' + uuidv4();
            });
            const uniqueAmount = computed(() => {
                return 'details-amount-' + uuidv4();
            });

            documentsList.value.forEach((item, i) => {
                item.color = CHART_COLORS[i] || CHART_COLORS[0];
            });

            const toggleDocument = (index) => {
                let filters = document.getElementById('reset-filters');
                filters.click();

                let searchB = getElementByPartialId('dm' + props.type);
                searchB.click();

                setTimeout(() => {
                    let itemSelect = document.getElementById(props.type + '-' + idFormat(documentsList.value[index].name));
                    itemSelect.click();

                    let table = document.getElementById('documentsTableWrapper');
                    table.scrollIntoView();
                }, 500)

            };

            onMounted(() => {
                renderDocumentsChart(chartEl.value, documentsList.value, router);

                // hide the note element on mount
                if (!document.querySelector(".note") === null) {
                    document.querySelector(".note").classList.add('hidden');
                }

                // get the details containers and clear them out if they have already been set
                const nameContainer = document.getElementById(uniqueName.value);
                const amountContainer = document.getElementById(uniqueAmount.value);
                let nameText = nameContainer.textContent;
                let amountText = amountContainer.textContent;
                nameContainer.textContent = nameText.replace(nameText, "");
                amountContainer.textContent = amountText.replace(amountText, "");
            });

            return {chartEl, CHART_COLORS, toggleDocument, documentsList, uniqueName, uniqueAmount};
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()).replace('program', 'Program');
            },
            clean: function(str) {
                return str.replace(/[^a-zA-Z0-9 ]/g, '');
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";

    .pie-chart-title {
        font-weight: 600;
        color: $blue--dark;
        font-size: 18px;
    }

    .pie-chart-wrapper {
        background-color: $white;
        border-radius: $border-radius;
        padding: 15px;
        box-shadow: $box-shadow--light;
    }
    .pie-chart-container svg {
        text {
            fill: $white;
        }

        path {
            stroke: $white;
            stroke-width: 5px;

            &:hover, &:active, &:focus {
                stroke-width: 0!important;
            }
        }
    }

    .pie-chart-container {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .pie-chart-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: $border-radius;
        padding: 5px;
        z-index: 2;

        &:hover, &:active, &:focus {
            display: block !important;
        }
    }

    .chart-legend {
        text-align: left;
        a {
            text-decoration: none;
            background-color: $white;
            border-radius: $border-radius;
            line-height: 35px;
            width: 100%;
            display: flex;
            padding-bottom: 10px;
            &.chart-highlighted {
                font-weight: bold;
            }
        }

        span {
            font-size: 2.4em;
            padding-right: 10px;
            line-height: 10px;
        }
    }

    .legend-trigger__title {
        font-size: 16px !important;
        font-weight: 400;
        padding-bottom: 5px;
        color: $blue--med;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;
        line-height: 1.2rem !important;
        width: 90%;
        position: relative;
        top: 0px;
        display: inline-block;
        &:hover, &:focus, &:active {
            color: $blue--dark;
        }
    }

    .note {
        display: block;
        width: 100%;
        margin-bottom: 0px;
        margin-top: 15px;
        text-align: center;
        &.hidden {
            display: none;
        }
    }
    .details-name, .details-amount {
        text-align: center;
        width: 100%;
        margin-bottom: 0px;
    }
    .swatch {
        width: 20px;
        height: 20px;
        display: inline-block;
        float: left;
        margin-right: 10px;
    }
</style>
